import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import { CameraAlt, KeyboardArrowDown } from "@mui/icons-material";
import { Box, Button, Card, Divider, Grid, styled, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AvatarBadge from "app/components/AvatarBadge";
import { FlexBetween, FlexBox } from "app/components/FlexBox";
import Bratislava from "app/components/icons/Bratislava";
import SettingsOutlined from "app/components/icons/SettingsOutlined";
import UserOutlined from "app/components/icons/UserOutlined";
import { H4, H5, Small } from "app/components/Typography";
import useAuth from "app/hooks/useAuth";
import { db } from '../../../config';
import { updateDoc, doc } from "firebase/firestore";

window.ResizeObserver = class {};

const updateUserProfile = async (uid, data) => {
  const userDocRef = doc(db, "Perfiles", uid);
  try {
    await updateDoc(userDocRef, data);
    alert("Perfil actualizado con éxito");
  } catch (error) {
    console.error("Error al actualizar el perfil:", error);
    alert("Error al actualizar el perfil. Inténtalo de nuevo.");
  }
};

// Styled components
const ContentWrapper = styled(Box)(({ theme }) => ({
  zIndex: 1,
  marginTop: 55,
  position: "relative",
  [theme.breakpoints.down("sm")]: { paddingLeft: 20, paddingRight: 20 },
}));

const CoverPicWrapper = styled(Box)(() => ({
  top: 0,
  left: 0,
  height: 125,
  width: "100%",
  overflow: "hidden",
  position: "absolute",
  backgroundColor: "#C6D3ED",
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
  margin: "auto",
  overflow: "hidden",
  borderRadius: "50%",
  border: "2px solid",
  borderColor: "white",
  backgroundColor: theme.palette.primary[200],
}));

const BasicInformation = () => {
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(-1);
  };
  const { userProfile, user, loading } = useAuth(); // Usamos el hook useAuth aquí
  const [ userData, setUserData ] = useState(null);

  const initialValues = {
    firstName: "Not assigned",
    lastName: "Not assigned",
    email: "Not assigned",
    phone: "Not assigned",
    organization: "Not assigned",
    department: "Not assigned",
    country: "Not assigned",
    state: "Not assigned",
    address: "Not assigned",
    maps: "Not assigned",
    role: "Not assigned",
    resume: "Not assigned",
    territory: "Not assigned"
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('Required').min(2, 'Too Short!').max(50, 'Too Long!'),
    lastName: Yup.string().required('Required').min(2, 'Too Short!').max(50, 'Too Long!'),
    email: Yup.string().email('Invalid email address').required('Required'),
    phone: Yup.string().required('Required').matches(/^\d{10}$/, 'Invalid phone number'),
    organization: Yup.string().max(100, 'Too Long!'),
    department: Yup.string().max(100, 'Too Long!'),
    country: Yup.string().max(100, 'Too Long!'),
    state: Yup.string().max(100, 'Too Long!'),
    address: Yup.string().max(200, 'Too Long!'),
    maps: Yup.string().max(50, 'Too Long!'),
    role: Yup.string().max(50, 'Too Long!'),
    resume: Yup.string().max(100, 'Too Long!'),
    territory: Yup.string().max(100, 'Too Long!')
  });
  

  const { values, errors, handleSubmit, handleChange, handleBlur, touched, resetForm } = useFormik({
    initialValues: userData || initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (user) {
        console.log("Usuario autenticado:", user);
        console.log("Datos del formulario:", values);
        const data = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          organization: values.organization,
          department: values.department,
          country: values.country,
          state: values.state,
          address: values.address,
          maps: values.maps,
          role: values.role,
          resume: values.resume,
          territory: values.territory
        };
        await updateUserProfile(user.email, data);
      }
    }
  });
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!loading) {
      if (!user) {
        console.log("Usuario no autenticado");
        // Puedes redirigir al usuario a la página de inicio de sesión, o mostrar un mensaje de error, etc.
        // Aquí puedes decidir cómo manejar la situación cuando el usuario no está autenticado.
        return; // Salir de la función useEffect si el usuario no está autenticado.
      }

      console.log("Usuario autenticado:", user);

      if (userProfile) {
        const newUserData = {
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          email: user.email,
          phone: userProfile.phone,
          organization: userProfile.organization,
          department: userProfile.department,
          country: userProfile.country,
          state: userProfile.state,
          address: userProfile.address,
          maps: userProfile.maps,
          role: userProfile.role,
          resume: userProfile.resume,
          territory: userProfile.territory
        };
        setUserData(newUserData);
        resetForm({ values: newUserData });
      }
    }
  }, [loading, userProfile, resetForm, user]);

  return (
    <Fragment>
      <Card sx={{ padding: 3, position: "relative" }}>
        <CoverPicWrapper>
          <img
            width="100%"
            height="100%"
            alt="Miembro del equipo"
            src="/assets/images/study-2.jpg"
            style={{ objectFit: "cover" }}
          />
        </CoverPicWrapper>

        <ContentWrapper>
          <FlexBox justifyContent="center">
            <AvatarBadge
              badgeContent={
                <label htmlFor="icon-button-file">
                  <input
                    type="file"
                    accept="image/*"
                    id="icon-button-file"
                    style={{ display: "none" }}
                  />

                  <IconButton aria-label="upload picture" component="span">
                    <CameraAlt sx={{ fontSize: 16, color: "background.paper" }} />
                  </IconButton>
                </label>
              }
            >
              <ImageWrapper>
                <img src="/assets/images/avatars/001-man.svg" alt="Team Member" sizes="large" />
              </ImageWrapper>
            </AvatarBadge>
          </FlexBox>

          <Box mt={2}>
          <H4 fontWeight={600} textAlign="center">
            {values.firstName} {values.lastName}
          </H4>

          <FlexBetween maxWidth={400} flexWrap="wrap" margin="auto" mt={1}>

          <FlexBox alignItems="center" gap={1}>
            <UserOutlined sx={{ color: "text.disabled" }} />
            <Small fontWeight={600} color="text.disabled">
              Perfil: {values.resume}
            </Small>
          </FlexBox>

          <FlexBox alignItems="center" gap={1}>
            <Bratislava sx={{ color: "text.disabled" }} />
            <Small fontWeight={600} color="text.disabled">
              Rol: {values.role}
            </Small>
          </FlexBox>
 
          <FlexBox alignItems="center" gap={1}>
            <SettingsOutlined sx={{ color: "text.disabled" }} />
            <Small fontWeight={600} color="text.disabled">
              Territorio: {values.territory}
            </Small>
          </FlexBox>

         </FlexBetween>
          </Box>
        </ContentWrapper>
      </Card>

      <Card sx={{ mt: 3 }}>
        <H5 padding={3}>Información básica</H5>
        <Divider />

        <form onSubmit={handleSubmit}>
        <Box margin={3}>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                name="firstName"
                label="First Name"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName} // Use values.lastName instead
                helperText={touched.firstName && errors.firstName}
                error={Boolean(touched.firstName && errors.firstName)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                name="lastName"
                label="Last Name"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName} // Use values.lastName instead
                helperText={touched.lastName && errors.lastName}
                error={Boolean(touched.lastName && errors.lastName)}
              />
            </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  helperText={touched.phone && errors.phone}
                  error={Boolean(touched.phone && errors.phone)}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="organization"
                  variant="outlined"
                  label="Organization"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.organization}
                  helperText={touched.organization && errors.organization}
                  error={Boolean(touched.organization && errors.organization)}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="department"
                  variant="outlined"
                  label="Department"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.department}
                  helperText={touched.department && errors.department}
                  error={Boolean(touched.department && errors.department)}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="country"
                  label="Country"
                  variant="outlined"
                  placeholder="Country"
                  SelectProps={{ native: true, IconComponent: KeyboardArrowDown }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.country}
                  helperText={touched.country && errors.country}
                  error={Boolean(touched.country && errors.country)}
                >
                </TextField>
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="state"
                  label="State"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.state}
                  helperText={touched.state && errors.state}
                  error={Boolean(touched.state && errors.state)}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="address"
                  label="Address"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  helperText={touched.address && errors.address}
                  error={Boolean(touched.address && errors.address)}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  name="maps"
                  label="Maps Code"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.maps}
                  helperText={touched.maps && errors.maps}
                  error={Boolean(touched.maps && errors.maps)}
                />
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" variant="contained">
                  Guardar
                </Button>
                <Button variant="outlined" sx={{ ml: 2 }} onClick={handleCancel}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Card>
    </Fragment>
  );
};

export default BasicInformation;
