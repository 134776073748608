import AuthGuard from "./auth/AuthGuard";
import Redirect from "./auth/Redirect";
import Account from "./views/account";
import DashboardLayout from "./components/layouts/Layout";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import pageLayoutRoutes from "./views/page-layouts/PageLayoutRoutees";
import pagesRoutes from "./views/pages/pagesRoutes";
import NotFound from "./views/sessions/NotFound";
import sessionRoutes from "./views/sessions/SessionRoutes";

const routes = [
  {
    element: (
      <AuthGuard routes={[
        ...dashboardRoutes,
        ...pageLayoutRoutes,
        ...pagesRoutes,
        { path: "/page-layouts/account", element: <Account /> },
      ]}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...pageLayoutRoutes,
      ...pagesRoutes,
      { path: "/page-layouts/account", element: <Account /> },
    ],
  },
  ...sessionRoutes,
  { path: "/", element: <Redirect /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
