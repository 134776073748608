import { firebaseConfig } from "config.js";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { createContext, useEffect, useReducer } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const initialAuthState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
  role: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FB_AUTH_STATE_CHANGED": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }
    case "SET_ROLE": {
      const { role } = action.payload;
      return { ...state, role };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "FIREBASE",
  createUserWithEmail: (email, password) => Promise.resolve(),
  signInWithEmail: (email, password) => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setRole: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const firestore = getFirestore();

  const setRole = (role) => {
    dispatch({ type: "SET_ROLE", payload: { role } });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch({ type: "FB_AUTH_STATE_CHANGED", payload: { isAuthenticated: true, user } });
      } else {
        dispatch({ type: "FB_AUTH_STATE_CHANGED", payload: { isAuthenticated: false, user: null } });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (state.isAuthenticated && state.user) {
      const fetchRole = async () => {
        const docRef = doc(firestore, "Perfiles", state.user.email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const { rol } = docSnap.data();
          setRole(rol);
        } else {
          console.log('El documento del usuario no fue encontrado');
        }
      };

      fetchRole();
    }
  }, [state.isAuthenticated, state.user, firestore]);

  const signInWithEmail = async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    dispatch({ type: "FB_AUTH_STATE_CHANGED", payload: { isAuthenticated: true, user: userCredential.user } });
    return userCredential;
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const logout = async () => {
    return signOut(auth);
  };

  const createUserWithEmail = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
        signInWithGoogle,
        method: "FIREBASE",
        signInWithEmail,
        createUserWithEmail,
        setRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
