import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

export const firebaseConfig = {
  apiKey: "AIzaSyA4J34wsYwdtfn-sH4IKUry0ZdPRgnlYbk",
  authDomain: "total-well-394201.firebaseapp.com",
  databaseURL: "https://total-well-394201-default-rtdb.firebaseio.com",
  projectId: "total-well-394201",
  storageBucket: "total-well-394201.appspot.com",
  messagingSenderId: "36958939169",
  appId: "1:36958939169:web:b20055dd21c46092a29b60",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};
