import AuthContext from "app/contexts/FirebaseAuthContext";
import { useContext, useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../config';

const useAuth = () => {
  const auth = useContext(AuthContext);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);  // <-- Nuevo estado para gestionar la carga

  useEffect(() => {
    console.log("useAuth useEffect triggered. Current auth.user:", auth.user);
    const fetchUserProfile = async () => {
        if (auth.user) {
          const docRef = doc(db, "Perfiles", auth.user.email);
          const docSnap = await getDoc(docRef);

                  if (docSnap.exists()) {
                    const profile = docSnap.data();
                    console.log("UserProfile desde Firebase:", profile);
                    setUserProfile(profile);
                } else {
                    console.log("No such document!");
                }
        }
        setLoading(false);
        console.log("Finished loading user profile in useAuth.");
      };

    fetchUserProfile();
}, [auth.user]);

  return {
    ...auth,
    userProfile,
    role: userProfile?.role,
    loading
  };
};

export default useAuth;
