import { lazy } from "react";
import Loadable from "app/components/Loadable";

const ProductList = Loadable(lazy(() => import("../pages/products/ProductList")));

const dashboardRoutes = [
  {
    path: "/pages/product-list",
    element: <ProductList />,
  },
];

export default dashboardRoutes;