import { authRoles } from "../app/auth/authRoles";

export const navigations = [
  {
    name: "Gestión de Productos",
    icon: "category",
    children: [
      { name: "Productos", path: "/pages/product-list", iconText: "PL" },
    ],
  },
  {
    name: "Usuarios",
    icon: "people",
    auth: authRoles.admin,
    children: [
      { name: "Mi Cuenta", path: "/page-layouts/account", iconText: "U3"},
      { name: "Usuarios y Perfiles", path: "/pages/user-list-3", iconText: "U3", auth: authRoles.admin},
    ],
  },
  {
    name: "Ver XML",
    icon: "launch",
    type: "extLink",
    path: "https://us-central1-total-well-394201.cloudfunctions.net/generateXML",
  },
];
