import useAuth from "app/hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import routes from "../../app/routes"

const flat = (arr) => {
  return arr.reduce((acc, curr) => {
    if (curr.children) {
      return [...acc, curr, ...flat(curr.children)];
    }
    return [...acc, curr];
  }, []);
};

const userHasPermission = (pathname, userProfile, routes) => {
  // Comprueba si userProfile es null o undefined
  if (!userProfile) {
    return false;
  }

  const matched = routes.find((r) => r.path === pathname);
  const authenticated = 
    matched && matched.auth && matched.auth.length ? matched.auth.includes(userProfile.role) : true;
  return authenticated;
};

const AuthGuard = ({ children }) => {
  const { pathname } = useLocation();
  const { isAuthenticated, userProfile, loading } = useAuth();

  if (loading) return null;

  const flatRoutes = flat(routes);
  const hasPermission = userHasPermission(pathname, userProfile, flatRoutes);
  let authenticated = isAuthenticated && hasPermission;

  if (authenticated) return <>{children}</>;

  return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
};

export default AuthGuard;
