import {
    alpha,
    Card,
    Divider,
    styled,
    Table,
    TableBody,
    TableHead,
    TableRow,
  } from "@mui/material";
  import { FlexBetween } from "app/components/FlexBox";
  import { H5, Span } from "app/components/Typography";
  import { BodyTableCell, HeadTableCell } from "./common/StyledComponents";
  import { useState, useEffect } from 'react';
  import { db } from '../../../config';
  import { collection, getDocs } from "firebase/firestore";
  
  const StyledStatus = styled(Span)(({ theme, error }) => ({
    padding: "3px 8px",
    borderRadius: "4px",
    color: error ? theme.palette.error.main : theme.palette.success.main,
    backgroundColor: alpha(error ? theme.palette.error.main : theme.palette.success.main, 0.2),
  }));
  
  const getHistoryLogs = async () => {
    const querySnapshot = await getDocs(collection(db, "Historial"));
    const historyData = [];
    querySnapshot.forEach((doc) => {
      historyData.push({ ...doc.data(), DocumentID: doc.id });
    });
    return historyData;
  };
  
  const HistoryLogs = () => {
    const [historyLogs, setHistoryLogs] = useState([]);
  
    useEffect(() => {
      getHistoryLogs().then(data => {
        setHistoryLogs(data);
      });
    }, []);
  
    return (
        <Card>
            <FlexBetween px={3} py={2}>
                <H5>Historial Overview</H5>
            </FlexBetween>

            <Divider />

            <Table sx={{ minWidth: 600 }}>
                <TableHead sx={{ backgroundColor: "grey.100" }}>
                    <TableRow>
                        <HeadTableCell style={{ fontSize: '11px', textAlign: 'left' }}>Type</HeadTableCell>
                        <HeadTableCell style={{ fontSize: '11px', textAlign: 'left' }}>Date</HeadTableCell>
                        <HeadTableCell style={{ fontSize: '11px', textAlign: 'left' }}>IP Address</HeadTableCell>
                        <HeadTableCell style={{ fontSize: '11px', textAlign: 'left' }}>Detail</HeadTableCell>
                        <HeadTableCell style={{ fontSize: '11px', textAlign: 'left' }}>
                            Sucursal<br />Producto
                        </HeadTableCell>
                        <HeadTableCell style={{ fontSize: '11px', textAlign: 'left'}}>User ID</HeadTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {historyLogs.map((log) => {
                        const dateTime = log.date.toDate().toLocaleString();
                        const date = dateTime.split(",")[0];
                        const time = dateTime.split(",")[1];

                        return (
                            <TableRow key={log.DocumentID}>
                                <BodyTableCell style={{ fontSize: '11px', textAlign: 'left' }}>
                                    <StyledStatus error={log.type === "Error"}>{log.type}</StyledStatus>
                                </BodyTableCell>
                                <BodyTableCell style={{ fontSize: '11px', textAlign: 'left' }}>
                                    {date} <br /> {time.trim()}
                                </BodyTableCell>
                                <BodyTableCell style={{ fontSize: '11px', textAlign: 'left' }}>{log.IPAddress}</BodyTableCell>
                                <BodyTableCell style={{ fontSize: '11px', textAlign: 'left' }}>{log.detail}</BodyTableCell>
                                <BodyTableCell style={{ fontSize: '11px', textAlign: 'left' }}>
                                    {log.sucursalId} <br />{log.productId}
                                </BodyTableCell>
                                <BodyTableCell style={{ fontSize: '11px', textAlign: 'left' }}>{log.userId}</BodyTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Card>

    );
  };
  
  export default HistoryLogs;
  