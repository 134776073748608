import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../auth/authRoles";

const UserList3 = Loadable(lazy(() => import("./user-list/UserList3")));
const ProductList = Loadable(lazy(() => import("./products/ProductList")));
const CustomerList = Loadable(lazy(() => import("./customers/CustomerList")));
const ProductViewer = Loadable(lazy(() => import("./products/ProductViewer")));
const CustomerViewer = Loadable(lazy(() => import("./customers/customer-viewer/CustomerViewer")));

const pagesRoutes = [
  { path: "/pages/user-list-3", element: <UserList3 />, auth: authRoles.admin},
  { path: "/pages/customer-list", element: <CustomerList /> },
  { path: "/pages/view-customer", element: <CustomerViewer /> },
  { path: "/pages/product-list", element: <ProductList /> },
  { path: "/pages/sucursal/:sucursalId/view-product/:productId", element: <ProductViewer />, auth: authRoles.admin },];
export default pagesRoutes;
