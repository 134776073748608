import { lazy } from "react";
import Loadable from "app/components/Loadable";

const UserProfile = Loadable(lazy(() => import("./user-profile/UserProfile")));

const pageLayoutRoutes = [
  { path: "/page-layouts/user-profile", element: <UserProfile /> },
];

export default pageLayoutRoutes;
